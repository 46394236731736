export var InitialHtmlElement = {
  set(el) {
    this.width = parseFloat(el.style.width);
    this.height = parseFloat(el.style.height);
    this.font_size = parseFloat(el.style.fontSsize);
    this.line_height = parseFloat(el.style.lineHeight);
    this.left = parseFloat(el.style.left);
    this.top = parseFloat(el.style.top);
  }
}


export class ScalableHtmlElement {
  constructor(el) {
    this.width = parseFloat(el.style.width);
    this.height = parseFloat(el.style.height);
    this.font_size = parseFloat(el.style.fontSsize);
    this.line_height = parseFloat(el.style.lineHeight);
    this.left = parseFloat(el.style.left);
    this.top = parseFloat(el.style.top);

    let domData = el.getBoundingClientRect();
    if (!this.width) {
      this.width = domData.width;
    }
    if (!this.height) {
      this.height = domData.height;
    }
    if (!this.left) {
      this.left = domData.left;
    }
    if (!this.top) {
      this.top = domData.top;
    }
  }
}
