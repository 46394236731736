<template>
  <div id="taskOrderTheLetters" class="namegameclass fullSize">
    <div ref="letterContainerRef" class="letterContainer">
      <div
        v-for="(letter, index) in letters"
        class="card orange"
        :class="{ selectedCard: selectedLetters.indexOf(index) !== -1 }"
        :ref="
          (el) => {
            if (el) letterRefs[index] = el;
          }
        "
        @click="selectItem(index)"
        :key="index"
      >
        {{ letter }}
      </div>

      <!--
        <div class="letterContainer" :set="lefti = 0">
            <div v-for="(letter, index) in letters"
                class="card orange"
                :class="{'selectedCard': selectedLetters.indexOf(index) !== -1}"
                :style="{'left': (lefti+=100)+'px'}"
                :ref="el => { if (el) letterRefs[index] = el}"
                @click="selectItem(index)"
                :key="index">
                    {{ letter }}
                </div>
            -->
      <!--
            <div class="absolute card custom_card orange noselect" style="top:0px; left:0px;"   data-letter-id="1">G</div>
            <div class="absolute card custom_card orange noselect" style="top:0px; left:120px;" data-letter-id="2">A</div>
            <div class="absolute card custom_card orange noselect" style="top:0px; left:240px;" data-letter-id="3">M</div>
            <div class="absolute card custom_card orange noselect" style="top:0px; left:360px;" data-letter-id="4">E</div>
            <div class="absolute card custom_card gray noselect"   style="top:0px; left:480px;" data-letter-id="5">W</div>
            <div class="absolute card custom_card gray noselect"   style="top:0px; left:600px;" data-letter-id="6">A</div>
            <div class="absolute card custom_card gray noselect"   style="top:0px; left:720px;" data-letter-id="7">Y</div>
            -->
    </div>
  </div>
</template>

<script>
import { onBeforeMount, computed, ref, onMounted, onBeforeUnmount } from "vue";
import { animate, shuffleArray } from "@utils/General";
import { ScalableHtmlElement } from "@utils/Scaler";

export default {
  name: "OrderTheLetters",
  props: ["data", "isTest", "taskId"],
  setup(props) {
    // Constants
    const letters = ref(props.data.options);
    const letterContainerRef = ref(null);
    const selectedLetters = ref([]);
    const isAnimation = ref(false);
    const letterRefs = ref([]);
    var elements = [];
    var currentRatio = 1;

    var animationDuration = 500;

    onBeforeMount(() => {
      shuffleArray(letters.value);
      window.addEventListener("resize", onResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });

    function onResize() {
      let domData = letterContainerRef.value.getBoundingClientRect();

      currentRatio = 1;
      let itemWidth = domData.width / letterRefs.value.length;
      let left = 0;
      for (let i in letterRefs.value) {
        if (itemWidth >= elements[i].width) {
          itemWidth = elements[i].width;
        } else {
          letterContainerRef.value.getBoundingClientRect();
          currentRatio =
            itemWidth / letterRefs.value[i].getBoundingClientRect().width;
        }
        letterRefs.value[i].style.width = itemWidth + "px";
        letterRefs.value[i].style.left = left + "px";

        left += itemWidth;
      }
    }

    onMounted(() => {
      letterContainerRef.value.style.height = "100px";

      let left = 0;
      for (let i in letterRefs.value) {
        letterRefs.value[i].style.left = left + "px";
        left += 100;

        elements.push(new ScalableHtmlElement(letterRefs.value[i]));
      }

      onResize();
    });

    function selectItem(index) {
      if (isAnimation.value) {
        return;
      }
      if (selectedLetters.value.indexOf(index) === -1) {
        selectedLetters.value.push(index);
      } else {
        selectedLetters.value.splice(index, 1);
      }

      if (selectedLetters.value.length === 2) {
        swapSelectedCards();
      }
    }

    function unselectAllCards() {
      selectedLetters.value = [];
    }

    function checkIsComplete() {
      //console.log("is complete?");
    }

    function sineMove(n) {
      let initial = 150;
      initial *= currentRatio;

      let i = Math.abs((n - 1) * 180);
      let pi = 3.14159265359;
      let rads = i * (pi / 180);
      let s = Math.sin(rads) * initial;
      return s;
    }

    function getDifference(firstCardLeft, secondCardLeft) {
      if (secondCardLeft >= firstCardLeft) {
        return secondCardLeft - firstCardLeft;
      }
      return firstCardLeft - secondCardLeft;
    }

    function getLeft(firstLeft, secondLeft, progress) {
      let difference = getDifference(firstLeft, secondLeft) * progress;
      if (firstLeft >= secondLeft) {
        return firstLeft - difference;
      }
      return firstLeft + difference;
    }

    /**
     * Swap animation
     *
     * @param duration int Animation duration
     * @param firstCardEl HTMLElement
     * @param firstCardLeft int|float
     * @param secondCardLeft int|float
     * @param sineMoveType string minus-plus sing
     */
    function swapAnimation(
      duration,
      firstCardEl,
      firstCardLeft,
      secondCardLeft,
      sineMoveType
    ) {
      animate({
        duration: duration,
        timing(timeFraction) {
          return timeFraction;
        },
        draw(progress) {
          let s = sineMoveType + sineMove(progress);

          let l = getLeft(firstCardLeft, secondCardLeft, progress);
          firstCardEl.style.top = s + "px";
          firstCardEl.style.left = l + "px";
        },
      });
    }

    function swapSelectedCards() {
      isAnimation.value = true;

      // Get HTML elements of cards
      let firstCardEl = letterRefs.value[selectedLetters.value[0]];
      let secondCardEl = letterRefs.value[selectedLetters.value[1]];

      // Get left positions of cards
      var firstCardLeft = parseFloat(firstCardEl.style.left);
      var secondCardLeft = parseFloat(secondCardEl.style.left);

      // Execute animations
      swapAnimation(
        animationDuration,
        firstCardEl,
        firstCardLeft,
        secondCardLeft,
        "+"
      );
      swapAnimation(
        animationDuration,
        secondCardEl,
        secondCardLeft,
        firstCardLeft,
        "-"
      );

      // Cleanup
      setTimeout(function () {
        isAnimation.value = false;
        unselectAllCards();
        checkIsComplete();
      }, animationDuration + 100);
    }

    return {
      letters: computed(() => letters.value),
      selectedLetters: computed(() => selectedLetters.value),
      selectItem,
      letterContainerRef,
      letterRefs,
    };
  },
};
</script>


<style lang="scss" scoped>
@-webkit-keyframes LetterSpacing {
  0% {
    letter-spacing: 0.5em;
  }
  50% {
    letter-spacing: 1em;
  }
  100% {
    letter-spacing: 0.5em;
  }
}

@-moz-keyframes LetterSpacing {
  0% {
    letter-spacing: 0.5em;
  }
  50% {
    letter-spacing: 1em;
  }
  100% {
    letter-spacing: 0.5em;
  }
}

@keyframes LetterSpacing {
  0% {
    letter-spacing: 0.5em;
  }
  50% {
    letter-spacing: 1em;
  }
  100% {
    letter-spacing: 0.5em;
  }
}

@-webkit-keyframes Jump {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 25%);
  }
  100% {
    transform: translate(0, 0%);
  }
}

@-moz-keyframes Jump {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 25%);
  }
  100% {
    transform: translate(0, 0%);
  }
}

@keyframes Jump {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 25%);
  }
  100% {
    transform: translate(0, 0%);
  }
}

.card {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid gray;
  border-radius: 5px;
  text-align: center;
  font-size: 2em;
  font-weight: 900;
  line-height: 100px;
  cursor: pointer;
}

.letterContainer {
  position: relative;
  display: flex;
}

.selectedCard {
  //border-color:white;
  background: green;
}

.orange {
  color: #de954c;
}
.gray {
  color: #87888a;
}
</style>